import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'

const urlBase = env.urlGeneral + '/returns'
export default class Protocols {
  static async getReasons() {
    return new Promise((res, rej) => {
      try {
        clientAPI.get(`${urlBase}/protocols/reasons/b2c`).then((response) => {
          res(response.data)
        })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async getSubReasons(fields) {    
    const request_query_string = new URLSearchParams(fields)
    return new Promise((res, rej) => {    
      clientAPI.get(`${urlBase}/protocols/subreasons`, {params: request_query_string})
        .then((response) => {          
          res(response.data)
        })
        .catch((err) => {          
          rej(err)
        })      
    })
  }

  static async loadSubjects(filters) {
    const defaultFilters = {
      ass_id: '',
      cto_id: '',
      ass_mot_ope: 'N',
      ass_ativo: 'S',
      ass_nome: '',
    }
    const requestBody = Object.assign({}, defaultFilters, filters)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .post(`${urlBase}/protocols/subjects/b2c`, requestBody)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async generateProtocol(fields) {
    const default_fields = {
      dadosGerais: {
        origem: '',
        marcaCodigo: 0,
        nomeCliente: '',
        cpfCliente: '',
        emailCliente: '',
        telefoneCliente: '',
        cepCliente: '',
        enderecoCliente: '',
        numeroEndrCliente: '',
        bairroCliente: '',
        cidadeCliente: '',
        UFCliente: '',
        complementoEndrCliente: '',
      },
      dadosBancarios: {
        ban_codigo: '',
        cli_agencia: '',
        cli_conta: '',
        cli_chavepix: '',
      },
      dadosItens: [
        {
          itemDevolucao: 1,
          agrupador: '',
          produtoCodigo: 0,
          produtoNome: '',
          produtoCor: '',
          produtoValor: '',
          motivoCodigo: 0,
          motivoNome: '',
          desejoCodigo: 0,
          desejoNome: '',
          gradeCodigo: 0,
          gradeTamanho: '',
          tamanhoTroca: '',
          empresaCodigo: 0,
          filialCodigo: 0,
          sequenciaFat: 0,
          itemFatSequencia: 0,
          numeroNF: 0,
          quantidadeNF: 0,
          qtdDevolucao: 0,
          observacao: '',
          nomeLoja: '',
          cnpjLoja: '',
          telefoneLoja: '',
          dataCompraLoja: '',
          enderecoLoja: '',
          cidadeLoja: '',
        },
      ],
    }
    const request_body = Object.assign({}, default_fields, fields)

    return new Promise((res, rej) => {
      
      clientAPI
        .post(`${urlBase}/protocols/generate/b2c`, request_body)
        .then((response) => {
          res(response.data)
        })
        .catch((error) => {
          rej(error)
        })
      
    })
  }

  static async loadBankData(fields) { 
    return new Promise((res, rej) => {
      try {
        clientAPI.get(`${urlBase}/bank`)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async getDataConsumer(fields) {    
    const request_query_string = new URLSearchParams(fields)
    return new Promise((res, rej) => {
      try {
        clientAPI.get(`${urlBase}/consumers`, {params: request_query_string}).then((response) => {
          res(response.data)
        })
      } catch (err) {
        rej(err)
      }
    })
  }
}
